//学生档案
<template>
    <div class="stu_record">
        <!-- 顶部的导航 -->
        <TopNavBar :tabArr="tabArr" @onItemClick="onItemClick"></TopNavBar>
        <!-- 切换路由 -->
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </div>
</template>
<script>
export default {
    data() {
        return {
            //顶部导航条
            tabArr: ["基本信息", "课表及考勤"],
        };
    },
    created() {
        let actUserInfo = this.$route.query.userInfo
        if (actUserInfo) {
            this.actUserInfo = JSON.parse(actUserInfo)
        }
    },
    methods: {
        //条目的点击事件
        onItemClick(pos) {
            switch (pos) {
                case 0:
                    this.$router.push({ path: "/Home/StuRecord/StudentInfo", query: {userInfo: JSON.stringify(this.actUserInfo)} });
                    break;
                case 1:
                    this.$router.push({
                        path: "/Home/StuRecord/StudentCourseCheck",
                        query: {userInfo: JSON.stringify(this.actUserInfo)}
                    });
                    break;
            }
        },
    },
    computed: {},
    components: {},
};
</script>
<style lang="scss" scoped>
.stu_record {
    margin: 0 14px;
    height: calc(100vh - 77px);
}
</style>  